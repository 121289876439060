.flexible-modal {
    position: fixed !important;
    z-index: 999999 !important;
    border: 1px solid #ccc;
    background: white;
    height: 80% !important;
}
.flexible-modal-mask {
    position: fixed !important;
    height: 100% !important;
    background: rgba(55, 55, 55, 0.6);
    top:0;
    left:0;
    right:0;
    bottom:0;
    opacity: 0;
}
.flexible-modal-resizer {
    position:absolute;
    right:0;
    bottom:0;
    cursor:se-resize;
    margin:5px;
    border-bottom: solid 2px #333;
    border-right: solid 2px #333;
}
.flexible-modal-drag-area{
    background: rgb(27, 36, 48) !important;
    height: 7%;
    width: 100% !important;
    position:absolute;
    right:0;
    top:0;
    cursor:move;
}
